.header {
  background-color: var(--color-grey100);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .wrapper {
  display: flex;
  padding-bottom: 0;
}

.header .wrapper .logo {
  height: 26px;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

main .wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 24px;
}

.basic-title {
  width: 100%;
  padding: 48px 0 0;
}

.wrapper {
  width: 1440px;
  padding: 0 60px 60px;
}

.board {
  display: flex;
  background: var(--color-white100);
  border: 1px solid var(--color-grey30);
  box-shadow: var(--drop-shadow);
  border-radius: var(--radius8);
  padding: 24px;
  flex-direction: column;
  flex-wrap: nowrap;
}

.main-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.main-container .note {
  padding-top: 20px;
}

.button-group button.network-option {
  font-size: var(--heading-20);
  height: 60px;
}

.advanced-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

ol.note {
  padding-left: 16px;
}

ol.note li{
  font-size: var(--body-14);
  color: var(--color-grey70);
  line-height: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.input-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  background: var(--color-white100);
  border: 1px solid var(--color-grey30);
  border-radius: var(--radius8);
  box-shadow: var(--input-drop-shadow);
  margin-bottom: 20px;
}

.input-toggle:hover {
  border: 1px solid var(--color-grey60);
}

.input-toggle .toggle-switch-switch,
.input-toggle .toggle-switch-inner {
  transition: none;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
}

.input-container .field {
  width: 100%;
}

.input-container .dropdown {
  width: 100%;
}

.input-container .dropdown .dropdown-trigger {
  width: 100%;
}

.input-container .dropdown .dropdown-trigger button {
  justify-content: start;
  text-align: left;
}

.input-container .file .filename {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.input-container .file .file-label {
  color: var(--color-grey80);
}

.input-container .file .disabled {
  pointer-events: none;
}

.input-container .file .file-label .file-cta {
  border-radius: var(--radius8);
  background: var(--color-white100);
  border-color: var(--color-grey40);
  height: 36px;
}

.input-container .file .file-label .file-cta:hover,
.input-container .file .file-label .file-cta:focus {
  border: 1px solid var(--color-grey60);
}

.input-container .file .file-label .file-cta .file-label {
  font-size: var(--body-14);
}

.input-container.is-hide,
.input-container.is-hide * {
  display: none;
}

.config-content {
  min-height: 100vh;
}

@media screen and (max-width: 1200px) {
  .width50 {
    width: 100%;
  }
  .wrapper {
    width: 100%;
    max-width: 800px;
    padding: 0 40px 60px;
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    padding: 0 20px 60px;
  }
}

.icon-input {
  position:relative;
 }

 .icon-input img {
  position:absolute;
  right:0;
  padding:10px 10px;
 }

 .help-container {
  display: flex;
  flex-direction: column;
}